@use './colors' as color;
@use './mixins' as mixins;

html {
    height: 100%;
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background-color: color.$white-color;
    font-size: 16px !important;
    height: 100%;
    overflow: hidden !important;

    &::-webkit-scrollbar {
        width: 0; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: color.$white-color; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
        background-color: color.$turquoise-dark; /* color of the scroll thumb */
        border: 3px solid color.$white-color; /* creates padding around scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
    }
}

main {
    height: 100%;
    margin: auto;
    width: 100%;

    // background: linear-gradient(180deg, color.$grey-light, color.$secondary-color, color.$white-color);
    // padding-bottom: 50px;
}

p,
span,
ul,
li,
button,
a,
select,
option,
h1,
h2,
h3,
h4 {
    color: color.$grey-dark;
}

.container {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 1300px;
    width: 100%;

    /* stylelint-disable-next-line */
    @media screen and (width <= 1300px) {
        padding: 0 10px;
    }

    /* stylelint-disable-next-line */
    @media screen and (width <= 600px) {
        padding: 0;
    }
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    background: none;
    border: unset;
    font-family: var(--font-noto);
}

select {
    background-color: color.$white-color;
    border: unset;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    padding: 5px;
    padding-right: 10px !important;
    position: relative;
}

.primarybutton {
    background-color: color.$turquoise-dark;
    border: 1px solid color.$turquoise-dark;
    border-radius: 40px;
    color: color.$white-color;
    cursor: pointer;
    font-weight: 700;
    margin: 2px;
    padding: 15px 25px;
}

.primarybuttonlink {
    border: unset;
    color: color.$turquoise-dark;
    cursor: pointer;
    margin: 2px;
    padding: 15px 25px;
    text-decoration: underline;
}

.buttonempty {
    border: unset;
    cursor: pointer;
    padding: 5px 20px;
}

.title {
    font-size: 1vw !important; // 17px

    /* stylelint-disable-next-line */
    @media screen and (max-width: mixins.$mobile-max-width) {
        font-size: 15px !important;
    }
}

.subtitle {
    font-size: 0.9vw !important; // 17px

    /* stylelint-disable-next-line */
    @media screen and (max-width: mixins.$mobile-max-width) {
        font-size: 13px !important;
    }
}

.carousel {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px !important;
    height: 550px;
    padding: 1%;
    position: relative;
    width: 80%;
}

.slick-list {
    height: 100%;
}

.slick-track {
    height: 100%;
}

.slick-slide {
    height: 100%;
    position: relative;
}

.slick-slider {
    height: 100%;
    position: relative;
}

.slick-initialized {
    height: 100%;
    position: relative;
}

.ant-carousel {
    height: 100%;
    position: relative !important;
    width: 100%;

    .slick-dots {
        li button {
            background-color: color.$white-color;
            height: 7px;
            top: -5px;
        }

        li.slick-active button {
            background-color: color.$secondary-color;
            height: 7px;
            top: -5px;
        }
    }
}

::-webkit-scrollbar {
    /* stylelint-disable-next-line */
    @media screen and (max-width: mixins.$mobile-max-width) {
        display: none;
    }
}
